const words = [
  'ไม่ใช่สลิ่มนะ',
  'ไม่ใช่ติ่งลุงตู่',
  'รักชาติ',
  'ชังชาติ',
  'ขายชาติ',
  'หนักแผ่นดิน',
  'เนรคุณแผ่นดิน',
  'เราคือผู้อยู่อาศัย',
  'ล้มเจ้า',
  'ส้มเน่า',
  'เผาไทย',
  'เห่อหมอยคลั่งชาติฝรั่ง',
  'รังนกสีฟ้า',
  'เก่งอยู่หลังคีย์บอร์ด',
  'ต่างชาติชักใยอยู่เบื้องหลัง',
  'ชักศึกเข้าบ้าน',
  'มีทุกอย่างที่ดีเพราะใคร',
  'พวกเผาบ้านเผาเมือง',
  'จำนำข้าว',
  'ศูนย์รวมจิตใจของชาติ',
  'ตอบแทนบุญคุณแผ่นดิน',
  'ควายแดง',
  'สวะส้ม',
  'ไอแม้ว',
  'ไม่ได้อยู่ฝั่งไหน',
  'ด่ารัฐบาลก็ด่าไป',
  'อาบน้ำร้อนมาก่อน',
  'พวกหัวรุนแรง',
  'โดนจูงจมูก',
  'ลุงเป็นคนดี',
  'เป็นกลาง',
  'พวกอันธพาล',
  'พวกขี้ข้าทักษิณ',
  'ซ้ายจัดดัดจริต',
  'โง่ไม่มีสมอง',
  'เป็นบุคคลสาธารณะ',
  'ทำไมไม่ยืน',
  'ประชามติ',
  'บังอาจก้าวล่วง',
  'เชียร์ลุง',
  'ม๊อบสวะ',
  'ไอ้เจ็กกบฎ',
  'รับไปห้าร้อย',
  'บ้านเมืองสงบ',
  'ระบอบทักษิณ',
  'สมบูรณาญาสิทธิทุน',
  'คนดี',
  'ก็ไม่ได้ชอบลุงตู่นะ',
  'มึงมาไล่ดูสิ',
  'ไปให้ตำรวจยิงหรอ',
  'ท่านทรงงานหนัก',
  'โดนแค่ฉีดน้ำทำเป็นบ่น',
  'หัวก้าวหน้า',
  'สัตว์นรก',
  'คนไทยทั้งประเทศ',
  'ขอพูดแรงๆ',
  'จะเปลี่ยนแปลงประเทศ',
  'ถ้าคนไทยฆ่ากันเอง',
  'ถ้าพวกมึงเป็นอะไรขึ้นมา',
  'เห็นเราเงียบ',
  'ถ้าพ่อมองลงมา',
  'พวกคอมมิวนิสต์',
  'ถอยกันคนละก้าว',
  'จ่ายภาษีหรือเปล่า',
  'ม็อบมุ้งมิ้ง',
  'คิดต่างได้',
  'รักประยุทธ์',
  'คุณภาพชีวิตจะดีขึ้น',
  'ทำร้ายตำรวจ',
  'เยาวชนปลดแอ๊ก',
  'ลุงตู่สู้ๆ',
  'เราจะทำตามสัญญา',
  'นายกคนปัจจุบัน',
  'ไม่ยอมกลับมารับโทษ',
  'ถ้ามึงไม่ชั่ว',
  'สลิ่ม',
  'พวกสามกีบ',
  'หมูขึ้นราคาบ่นกันแพง',
  'ถ้าความรู้น้อย',
  'ผักชีแพงก็ด่า',
  'ต้มจืดตำลึง',
  'ตำรวจดีๆ',
  'ฝั่งประชาธิปไตยเหี้ยอะไร',
]

export default words
